<template>
    <div class="card flex flex-column justify-content-center p-8">
        <div v-if="tokenValido && !showFinalizado">
            <h1>Atualização de cadastro</h1>
            <Accordion :activeIndex="0" class="w-full">
                <AccordionTab header="Horário de atendimento">
                    <DataTable :value="form.openingHours" class="editable-cells-table" responsiveLayout="scroll">
                        <Column field="weekDay" header="Dia da semana">
                            <template #body="{ data }">
                                <Dropdown :disabled="desabilitar()" v-model="data.weekDay"
                                    @change="checkDisabledOptions(data)" :options="weekDays" optionValue="value"
                                    optionLabel="name" placeholder="---" />
                            </template>
                        </Column>
                        <Column field="atendimentoMatutino" header="Atendimento Matutino">
                            <template #body="{ data }">
                                <div class="field-checkbox">
                                    <Checkbox :disabled="desabilitar()" id="binary" v-model="data.atendimentoMatutino"
                                        :binary="true" @click="clearHorariosManha(data)" />
                                </div>
                            </template>
                        </Column>
                        <Column field="startHourAm" header="Horário início AM">
                            <template #body="{ data }">
                                <InputMask class="p-inputtext-sm" v-model="data.startHourAm"
                                    :disabled="!data.atendimentoMatutino || desabilitar()" mask="99:99" />
                            </template>
                        </Column>
                        <Column field="endHourAm" header="Horário fim AM">
                            <template #body="{ data }">
                                <InputMask class="p-inputtext-sm" v-model="data.endHourAm"
                                    :disabled="!data.atendimentoMatutino || desabilitar()" mask="99:99" />
                            </template>
                        </Column>
                        <Column field="serviceTypeAm" header="Tipo atendimento AM">
                            <template #body="{ data }">
                                <Dropdown v-model="data.serviceTypeAm" :options="serviceTypes"
                                    :disabled="!data.atendimentoMatutino || desabilitar()" optionValue="value"
                                    optionLabel="name" placeholder="---" />
                            </template>
                        </Column>
                        <Column field="atendimentoVespertino" header="Atendimento Vespertino">
                            <template #body="{ data }">
                                <div class="field-checkbox">
                                    <Checkbox id="binary" v-model="data.atendimentoVespertino" :binary="true"
                                        @click="clearHorariosTarde(data)" :disabled="desabilitar()" />
                                </div>
                            </template>
                        </Column>

                        <Column field="startHourPm" header="Horário início PM">
                            <template #body="{ data }">
                                <InputMask class="p-inputtext-sm" v-model="data.startHourPm" mask="99:99"
                                    :disabled="!data.atendimentoVespertino || desabilitar()" />
                            </template>
                        </Column>
                        <Column field="endHourPm" header="Horário fim PM">
                            <template #body="{ data }">
                                <InputMask class="p-inputtext-sm" v-model="data.endHourPm" mask="99:99"
                                    :disabled="!data.atendimentoVespertino || desabilitar()" />
                            </template>
                        </Column>
                        <Column field="serviceTypePm" header="Tipo atendimento PM ">
                            <template #body="{ data }">
                                <Dropdown v-model="data.serviceTypePm" :options="serviceTypes"
                                    :disabled="!data.atendimentoVespertino || desabilitar()" optionValue="value"
                                    optionLabel="name" placeholder="---" />
                            </template>
                        </Column>
                    </DataTable>

                    <div class="flex flex-column w-full mt-4">
                        <label for="textoAtualizacaoHorarios">Caso haja algum ajuste no horário de atendimento nos
                            descreva abaixo</label>
                        <Textarea id="textoAtualizacaoHorarios" v-model="form.textoAtualizacaoHorarios" rows="3"
                            cols="20" />
                    </div>
                </AccordionTab>

                <AccordionTab header="Endereço">
                    <div>
                        <div class="grid">
                            <div class="flex flex-column col-6">
                                <label for="zipCode">CEP</label>
                                <InputMask id="zipCode" mask="99999-999" v-model.trim="form.zipCode" autocomplete="off"
                                    :disabled="desabilitar()" />
                            </div>

                            <div class="flex flex-column col-6">
                                <label for="neighborhood">Bairro</label>
                                <InputText id="neighborhood" v-model.trim="form.neighborhood" autocomplete="off"
                                    :disabled="desabilitar()" />
                            </div>
                        </div>

                        <div class="grid">
                            <div class="flex flex-column col-6">
                                <label for="street">Rua</label>
                                <InputText id="street" v-model.trim="form.street" autocomplete="off"
                                    :disabled="desabilitar()" />
                            </div>
                            <div class="flex flex-column col-6">
                                <label for="number">Número</label>
                                <InputText id="number" v-model.trim="form.number" autocomplete="off"
                                    :disabled="desabilitar()" />
                            </div>
                        </div>

                        <div class="grid">
                            <div class="flex flex-column col-12">
                                <label for="city">Cidade</label>
                                <DropdownCidade v-model="form.city" :disabled="desabilitar()" />
                            </div>
                        </div>

                        <div class="flex flex-column">
                            <label for="addressComplement">Complemento</label>
                            <InputText id="addressComplement" v-model.trim="form.addressComplement" autocomplete="off"
                                :disabled="desabilitar()" />
                        </div>

                        <div class="flex flex-column mt-2">
                            <label for="addressReference">Referência</label>
                            <InputText id="addressReference" v-model.trim="form.addressReference" autocomplete="off"
                                :disabled="desabilitar()" />
                        </div>
                    </div>
                </AccordionTab>

                <AccordionTab header="Dados para faturamento">
                    <div class="grid">
                        <div class="flex flex-column col">
                            <label for="empresas">Método de pagamento</label>
                            <Dropdown :disabled="desabilitar()" id="metodoPagamento" v-model="form.metodoPagamento"
                                :options="metodoPagamento" optionLabel="name" optionValue="value" dataKey="id"
                                placeholder="Selecione..." />
                        </div>
                        <div v-if="form.metodoPagamento === metodoPagamentoEnum.PIX" class="flex flex-column col">
                            <label for="empresas">Chave Pix</label>
                            <InputText id="chavePix" v-model="form.chavePix" :options="metodoPagamento"
                                :disabled="desabilitar()" />
                        </div>
                    </div>
                    <div v-if="form.metodoPagamento != metodoPagamentoEnum.PIX">
                        <div class="flex flex-column mb-2">
                            <label for="nomeTitularConta">Nome titular da conta</label>
                            <InputText id="titularConta" v-model.trim="form.nomeTitularConta" autocomplete="off"
                                :disabled="desabilitar()" />
                        </div>
                        <div class="grid">
                            <div class="flex flex-column col-6">
                                <label for="cpfTitularConta">CPF titular</label>
                                <InputMask id="cpfTitularConta" mask="999.999.999-99"
                                    v-model.trim="form.cpfTitularConta" autocomplete="off" :disabled="desabilitar()" />
                            </div>
                            <div class="flex flex-column col-6">
                                <label for="cnpjTitularConta">CNPJ titular</label>
                                <InputMask id="cnpjTitularConta" mask="99.999.999/9999-99"
                                    v-model.trim="form.cnpjTitularConta" autocomplete="off" :disabled="desabilitar()" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="flex flex-column col-6">
                                <label for="codigoBanco">Código do Banco</label>
                                <InputMask id="codigoBanco" mask="999" v-model.trim="form.codigoBanco"
                                    autocomplete="off" :disabled="desabilitar()" />
                            </div>
                            <div class="flex flex-column col-6">
                                <label for="nomeBanco">Nome do Banco</label>
                                <InputText id="nomeBanco" v-model.trim="form.nomeBanco" autocomplete="off"
                                    :disabled="desabilitar()" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="flex flex-column col-6">
                                <label for="codigoAgencia">Codigo Agência</label>
                                <InputText id="codigoAgencia" v-model.trim="form.codigoAgencia" autocomplete="off"
                                    :disabled="desabilitar()" />
                                <small class="p-info">Formatos aceitos: 0000 | 0000-0</small>
                            </div>
                            <div class="flex flex-column col-6">
                                <label for="contaCorrente">Conta Corrente</label>
                                <InputText id="contaCorrente" v-model.trim="form.contaCorrente" autocomplete="off"
                                    :disabled="desabilitar()" />
                                <small class="p-info">Formatos aceitos: 00000-0 | 000000-0 | 0000000-0</small>
                            </div>
                        </div>
                    </div>
                </AccordionTab>

                <AccordionTab header="Contato">
                    <div>
                        <div class="flex flex-column mb-2">
                            <label for="email">E-mail</label>
                            <InputText id="email" v-model.trim="form.email" autocomplete="off"
                                :disabled="desabilitar()" />
                        </div>
                        <div class="grid">
                            <div class="flex flex-column col-6">
                                <label for="phone">Telefone</label>
                                <InputMask id="phone" mask="(99) 99999-9999" v-model.trim="form.phone"
                                    autocomplete="off" :disabled="desabilitar()" />
                            </div>

                            <div class="flex flex-column col-6">
                                <label for="commercial_phone">Telefone Comercial</label>
                                <InputMask id="commercial_phone" mask="(99) 9999-9999"
                                    v-model.trim="form.commercialPhone" autocomplete="off" :disabled="desabilitar()" />
                            </div>
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <div class="flex align-content-center mt-4">
                <InputSwitch id="naoAtualizarDados" class="mr-2" v-model="form.naoAtualizaDados" />
                <label for="naoAtualizarDados">Não preciso atualizar meus dados</label>
            </div>

            <div class="flex flex-column w-full mt-4">
                <label for="textoAtualizacaoGeral">Utilize este campo para informar atualizações no seu cadastro</label>
                <Textarea id="textoAtualizacaoGeral" v-model="form.textoAtualizacaoGeral" rows="3" cols="20" />
            </div>
            <div class="flex justify-content-center mt-2">
                <Button label="Atualizar meu cadastro" @click="enviarDadosCadastro" />
            </div>
        </div>
        <div v-if="!tokenValido" class="flex flex-column align-items-center">
            <h1> Dados não encontrados ou Token inválido/expirado </h1>
            <i class="pi pi-times" style="font-size: 3.0rem; color: red"></i>
        </div>

        <div v-if="showFinalizado" class="flex flex-column align-items-center justify-content-center">
            <h1>Obrigado</h1>
            <img src="../../../../public/layout/images/atualizacao-prestador/finalAtualizaPrestador.svg"
                style="width: auto" />
            <h2>Fique tranquilo, nós cuidamos do resto</h2>
            <label>Seu cadastro está sendo atualizado em nossa plataforma.</label>
        </div>
    </div>
</template>

<script>
import MetodoPagamentoEnum from '../../../enums/MetodoPagamentoEnum';
import BaseService from '../../../services/BaseService';
import PrestadorAtualizacaoService from '../../../services/PrestadorAtualizacaoService';
import DropdownCidade from '../../cidades/components/DropdownCidade.vue';

export default {
    components: {
        DropdownCidade
    },
    props: {
        token: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            metodoPagamento: [],
            metodoPagamentoEnum: MetodoPagamentoEnum,
            form: {
                openingHours: [],
                textoAtualizacaoGeral: null,
                textoAtualizacaoHorarios: null,
                naoAtualizaDados: false
            },
            horarios: [
                { id: 1, periodo: '08:00 - 12:00' },
                { id: 2, periodo: '13:30 - 18:00' }
            ],
            weekDays: [
                { value: 'DOM', name: 'Domingo' },
                { value: 'SEG', name: 'Segunda' },
                { value: 'TER', name: 'Terça' },
                { value: 'QUA', name: 'Quarta' },
                { value: 'QUI', name: 'Quinta' },
                { value: 'SEX', name: 'Sexta' },
                { value: 'SAB', name: 'Sábado' }
            ],
            serviceTypes: [
                { value: 'ORDEM_CHEGADA', name: 'Ordem de chegada' },
                { value: 'HORARIO_MARCADO', name: 'Horário marcado' }
            ],
            submitted: false,
            tokenValido: false,
            showFinalizado: false
        }
    },
    created() {
        this.$service = new PrestadorAtualizacaoService();
        this.$serviceSuppliers = new BaseService('/suppliers');
    },
    async mounted() {
        this.MetodoPagamentoOptions();
        try {
            const { data } = await this.$service.findByToken(this.$route.params.token || this.token);
            this.form = data.prestador;
            this.tokenValido = true;
        } catch (error) {
            alert('Token inválido ou expirado.');
        }
    },
    methods: {
        MetodoPagamentoOptions() {
            this.metodoPagamento = [
                { name: 'PIX', value: this.metodoPagamentoEnum.PIX },
                { name: 'Faturamento', value: this.metodoPagamentoEnum.FATURAMENTO },
                { name: 'Transferência', value: this.metodoPagamentoEnum.TRANSFERENCIA }
            ];
            if (this.depositoAntecipado) {
                this.metodoPagamento = [
                    { name: 'PIX', value: this.metodoPagamentoEnum.PIX },
                    { name: 'Transferência', value: this.metodoPagamentoEnum.TRANSFERENCIA }
                ];
            }
        },
        clearHorariosManha(horario) {
            horario.serviceTypeAm = null;
            horario.startHourAm = '00:00';
            horario.endHourAm = '00:00';
            return horario;
        },
        clearHorariosTarde(horario) {
            horario.startHourPm = '00:00';
            horario.endHourPm = '00:00';
            horario.serviceTypePm = null;
            return horario;
        },
        desabilitar() {
            return true;
        },
        removerDiaAtendimento(data) {
            this.form.openingHours = this.form.openingHours.filter((item) => item != data);
        },
        adicionarHorario() {
            this.form.openingHours = this.form.openingHours || [];
            this.form.openingHours.push({ startHourAm: '00:00', endHourAm: '00:00', startHourPm: '00:00', endHourPm: '00:00' });
        },
        checkDisabledOptions(option) {
            const retorno = this.form?.openingHours?.filter((r) => r?.weekDay == option?.weekDay);
            if (retorno.length > 1) {
                option.weekDay = null;
                alert('Este dia da semana ja foi selecionado!');
            }
        },
        async enviarDadosCadastro() {
            try {
                const data = {
                    textoAtualizacaoHorarios: this.form.textoAtualizacaoHorarios,
                    textoAtualizacaoGeral: this.form.textoAtualizacaoGeral,
                    naoAtualizaDados: this.form.naoAtualizaDados,
                    token: this.$route.params.token
                };
                await this.$service.gerarAtualizacaoPrestador(data);

                this.showFinalizado = true;
            } catch (error) {
                alert('Error: ', error);
            }
        }
    }
}
</script>

<style></style>
